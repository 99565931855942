.projects__grid {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
  grid-gap: 1.2em;
}

.btn-container {
  padding: 20px;
  text-align: center;
  width: 200px;
  margin: auto;
}
